<template>
  <v-main
    id="application"
    style="back"
  >
    <v-responsive
      :style="styles"
      min-height="100vh"
      class="d-flex align-start"
    >
      <router-view />
    </v-responsive>
  </v-main>
</template>

<script>
  import customColors from '@/styles/appcolors'
  export default {
    name: 'ApplicationCoreView',
    data: function () {
      return {
        pagestyles: {
          background: customColors.ViewPage.background,
        },
      }
    },
    computed: {
      styles () {
        const paddingTop = this.$vuetify.breakpoint.mdAndUp ? 0 : 110
        // const paddingBottom = this.$vuetify.breakpoint.mdAndUp ? 0 : 0
        return {
          padding: `${paddingTop}px 0`,
          backgroundColor: customColors.ViewPage.background,
        }
      },
    },
  }
</script>
